<template>
  <div id="category">
      分类
  </div>
</template>

<script>
export default {
    name: 'Category',
}
</script>

<style scoped>

</style>